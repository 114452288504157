import Column from '@/model/shared/Column';

export const CONFIG = {
  headers: [
    new Column('CAMPAIGN / PRODUCT', 'name').setClass('font-bold'),
    new Column('CREATED BY', 'userName'),
    new Column('TYPE REPORT', 'type'),
    new Column('SF ID', 'sfId'),
    new Column('STYLE', 'style'),
    new Column('GROUP BY', 'groupBy'),
    new Column('TIMEZONE', 'timezone'),
    new Column('EMAILS', 'addresses'),
    new Column('STATUS', 'status'),
    // new Column('ACTIONS', 'actions').setClass('w-2'),
  ],
};
