<template>
  <div class="flex flex-row">
    <sun-input
      id="input-search"
      v-model="value"
      :disabled="disabled"
      class="rounded-l shadow-none"
      type="search"
      :placeholder="placeholder"
      prevent-enter
      @change="onChangeInput"
    />
    <sun-button
      id="input-button"
      extra-classes="px-3 rounded-r border-r border-t border-b border-gray-300 focus:shadow-none"
      :shadow="false"
      :disabled="disabled"
      @click="onClick"
    >
      <div class="flex items-center w-6">
        <search-svg />
      </div>
    </sun-button>
  </div>
</template>

<script>
import SearchSvg from '@/components/icons/SearchSvg.vue';
import CONFIG from '@/components/molecules/shared/inputSearch/config';

export default {
  name: 'InputSearchButton',
  components: { SearchSvg },
  props: {
    placeholder: {
      type: String,
      default: () => CONFIG.placeholder,
    },
    disabled: {
      type: Boolean,
      default: () => CONFIG.disabled,
    },
  },
  data() {
    return { value: null };
  },
  methods: {
    onClick() {
      this.$emit('clickSearch', this.value);
    },
    onChangeInput() {
      if (!this.value) {
        this.$emit('onClear');
      } else {
        this.$emit('onChange', this.value);
      }
    },
  },
};
</script>

<style scoped>
::v-deep #input-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

::v-deep #input-search {
  box-shadow: none;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
