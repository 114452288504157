var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-row w-full justify-end" },
    [
      _c("input-search-button", {
        staticClass: "justify-end w-1/4",
        attrs: {
          placeholder: "Search by NAME or SF ID",
          disabled: _vm.disabled,
        },
        on: {
          onClear: _vm.onClearSearch,
          clickSearch: function ($event) {
            return _vm.onClickSearch($event)
          },
          onChange: function ($event) {
            return _vm.onChangeSearch($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }