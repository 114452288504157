var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-row" },
    [
      _c("sun-input", {
        staticClass: "rounded-l shadow-none",
        attrs: {
          id: "input-search",
          disabled: _vm.disabled,
          type: "search",
          placeholder: _vm.placeholder,
          "prevent-enter": "",
        },
        on: { change: _vm.onChangeInput },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "sun-button",
        {
          attrs: {
            id: "input-button",
            "extra-classes":
              "px-3 rounded-r border-r border-t border-b border-gray-300 focus:shadow-none",
            shadow: false,
            disabled: _vm.disabled,
          },
          on: { click: _vm.onClick },
        },
        [
          _c(
            "div",
            { staticClass: "flex items-center w-6" },
            [_c("search-svg")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }