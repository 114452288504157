var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "before-status",
            fn: function () {
              return [
                _c("scheduled-emails-filters", {
                  on: {
                    onClickInputSearch: function ($event) {
                      return _vm.onClickInputSearch($event)
                    },
                    onClearSearch: _vm.onClearSearch,
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-data-table", {
                  staticClass: "mt-2",
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.scheduledEmails,
                    hoverable: "",
                    loading: _vm.isLoading,
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u([
                    {
                      key: "empty",
                      fn: function () {
                        return [
                          _c("asterix-no-data", { staticClass: "bg-white" }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("sun-pagination-page", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                  key: _vm.currentPage,
                  staticClass: "my-6",
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                  },
                  on: {
                    changePage: function ($event) {
                      return _vm.goToPage({ page: $event })
                    },
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }