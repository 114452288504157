<template>
  <div class="flex flex-row w-full justify-end">
    <input-search-button
      class="justify-end w-1/4"
      placeholder="Search by NAME or SF ID"
      :disabled="disabled"
      @onClear="onClearSearch"
      @clickSearch="onClickSearch($event)"
      @onChange="onChangeSearch($event)"
    />
  </div>
</template>

<script>
import InputSearchButton from '@/components/molecules/shared/inputSearch/InputSearchButton.vue';
import CONFIG from '@/components/molecules/shared/inputSearch/config';

export default {
  name: 'ScheduledEmailsFilters',
  components: { InputSearchButton },
  props: {
    disabled: {
      type: Boolean,
      default: () => CONFIG.disabled,
    },
  },
  data() {
    return {
      valueToSearch: null,
    };
  },
  methods: {
    onClickSearch(value) {
      this.$emit('onClickInputSearch', value);
    },
    onClearSearch() {
      this.$emit('onClearSearch');
    },
    onChangeSearch(value) {
      this.$emit('onChangeSearch', value);
    },
  },
};
</script>

<style scoped></style>
