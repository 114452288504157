<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #before-status>
        <scheduled-emails-filters @onClickInputSearch="onClickInputSearch($event)" @onClearSearch="onClearSearch" />
      </template>
      <template #content>
        <sun-data-table
          :headers="headers"
          :content="scheduledEmails"
          hoverable
          :loading="isLoading"
          class="mt-2"
          @sort="onSortTable"
        >
          <!--          <template #[`col.actions`]="{ item, columnClass }">-->
          <!--            <sun-data-table-cell :class="columnClass">-->
          <!--              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />-->
          <!--            </sun-data-table-cell>-->
          <!--          </template>-->

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination-page
          v-show="!isLoading"
          :key="currentPage"
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          class="my-6"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AsterixSection from '@/components/templates/AsterixSection.vue';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { indexMixin } from '@/mixins/index/indexMixin';
import { CONFIG } from '@/views/private/modules/thirdParty/scheduledEmails/config';
import { getScheduleReports } from '@/services/modules/ThirdParty/mails';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import ScheduledEmailsFilters from '@/views/private/modules/thirdParty/scheduledEmails/ScheduledEmailsFilters.vue';

export default {
  name: 'ThirdPartyScheduledEmailsList',
  components: {
    ScheduledEmailsFilters,
    AsterixSection,
    // TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
  },
  mixins: [activeClientMixin, indexMixin],
  data() {
    return {
      isLoading: false,
      scheduledEmails: [],
      headers: CONFIG.headers,
      // actions: [{ name: 'Edit' }],
    };
  },
  mounted() {
    this.getTableItems();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async getTableItems(filterName) {
      this.sectionStatus = SECTION_STATUS.OK;
      this.isLoading = true;
      try {
        const params = new QueryParamsBuilder(this.currentPage, this.itemsPerPage);
        params.addSort('createdAt', 'DESC');

        if (filterName) {
          params.addFilter('name', filterName);
          params.addFilter('SFReportId', filterName);
        }

        const { data, meta } = await getScheduleReports(params);
        this.scheduledEmails = data;
        this.totalPages = meta.totalPages;
        this.totalItems = meta.totalResults;
        if (data.length === 0) {
          this.sectionStatus = SECTION_STATUS.EMPTY;
        }
      } catch (e) {
        this.createToast(Toast.error('Failed get emails', e.message));
        this.sectionStatus = SECTION_STATUS.ERROR;
      }
      this.isLoading = false;
    },
    onClickInputSearch(value) {
      this.getTableItems(value);
    },
    onClearSearch() {
      this.getTableItems();
    },
  },
};
</script>

<style scoped></style>
